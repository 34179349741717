<template>
    <div>
        <!-- Início filtro -->
        <v-dialog
            v-model="modal.filtro"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar
                    color="#018656"
                    dark
                >
                    <v-app-bar-nav-icon @click="modal.filtro = false">
                        <v-icon>fas fa-times</v-icon>
                    </v-app-bar-nav-icon>
                    <v-icon
                        class="ml-2"
                    >
                        fas fa-clock
                    </v-icon>
                    <v-toolbar-title
                        class="pl-4"
                    >
                        Filtrar objetos atrasados
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-app-bar-nav-icon
                        @click="limparFiltros"
                    >
                        <v-icon>fas fa-broom</v-icon>
                    </v-app-bar-nav-icon>
                    <v-app-bar-nav-icon
                        @click="grid.objetos.paginaAtual = 1, carregarObjetos(), modal.filtro = false"
                    >
                        <v-icon>fas fa-search</v-icon>
                    </v-app-bar-nav-icon>
                </v-toolbar>
                <v-card-text>
                    <v-col
                        class="pt-6"
                    >
                        <v-form>
                            <v-row>
                                <v-col
                                    cols="12"
                                    md="3"
                                >
                                    <v-text-field
                                        dense
                                        outlined
                                        prepend-icon="fas fa-fingerprint"
                                        label="Código"
                                        type="number"
                                        autocomplete="off"
                                        v-model="filtros.codigo"
                                        clearable
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="3"
                                >
                                    <v-select
                                        dense
                                        outlined
                                        prepend-icon="fas fa-shipping-fast"
                                        label="Transportadoras"
                                        v-model="filtros.cod_transportadora"
                                        :items="transportadoras"
                                        item-text="transportadora"
                                        item-value="codigo"
                                        clearable
                                    ></v-select>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="3"
                                >
                                    <v-text-field
                                        dense
                                        outlined
                                        prepend-icon="fas fa-dolly"
                                        label="Rastreio"
                                        type="text"
                                        autocomplete="off"
                                        v-model="filtros.numero_rastreio"
                                        clearable
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="3"
                                >
                                    <v-text-field
                                        dense
                                        outlined
                                        prepend-icon="fas fa-clock"
                                        label="Prazo"
                                        type="number"
                                        autocomplete="off"
                                        v-model="filtros.prazo"
                                        clearable
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="12"
                                    md="3"
                                >
                                    <v-dialog
                                        ref="modal_dt_considerada"
                                        v-model="modal.dt_considerada"
                                        :return-value.sync="filtros.dt_considerada"
                                        persistent
                                        width="290px"
                                        :retain-focus="false"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                dense
                                                outlined
                                                prepend-icon="fas fa-calendar-alt"
                                                label="Dt. Considerada"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                v-model="filtros.dt_considerada"
                                                clearable
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="filtros.dt_considerada"
                                            scrollable
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="modal.dt_considerada = false"
                                            >Cancelar</v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.modal_dt_considerada.save(filtros.dt_considerada)"
                                            >OK</v-btn>
                                        </v-date-picker>
                                    </v-dialog>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="3"
                                >
                                    <v-dialog
                                        ref="modal_dt_prevista"
                                        v-model="modal.dt_prevista"
                                        :return-value.sync="filtros.dt_prevista"
                                        persistent
                                        width="290px"
                                        :retain-focus="false"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                dense
                                                outlined
                                                prepend-icon="fas fa-calendar-alt"
                                                label="Dt. Prevista"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                v-model="filtros.dt_prevista"
                                                clearable
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="filtros.dt_prevista"
                                            scrollable
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="modal.dt_prevista = false"
                                            >Cancelar</v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.modal_dt_prevista.save(filtros.dt_prevista)"
                                            >OK</v-btn>
                                        </v-date-picker>
                                    </v-dialog>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="3"
                                >
                                    <v-text-field
                                        dense
                                        outlined
                                        prepend-icon="fas fa-map-marker-alt"
                                        label="Cep Origem"
                                        type="number"
                                        autocomplete="off"
                                        v-model="filtros.cep_origem"
                                        clearable
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="3"
                                >
                                    <v-text-field
                                        dense
                                        outlined
                                        prepend-icon="fas fa-map-marker-alt"
                                        label="Cep Destino"
                                        type="number"
                                        autocomplete="off"
                                        v-model="filtros.cep_destino"
                                        clearable
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="12"
                                    md="3"
                                >
                                    <v-dialog
                                        ref="modal_dt_ultima_posicao"
                                        v-model="modal.dt_ultima_posicao"
                                        :return-value.sync="filtros.dt_ultima_posicao"
                                        persistent
                                        width="290px"
                                        :retain-focus="false"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                dense
                                                outlined
                                                prepend-icon="fas fa-calendar-alt"
                                                label="Dt. Últ. Mov."
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                v-model="filtros.dt_ultima_posicao"
                                                clearable
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="filtros.dt_ultima_posicao"
                                            scrollable
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="modal.dt_ultima_posicao = false"
                                            >Cancelar</v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.modal_dt_ultima_posicao.save(filtros.dt_ultima_posicao)"
                                            >OK</v-btn>
                                        </v-date-picker>
                                    </v-dialog>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="9"
                                >
                                    <v-select
                                        dense
                                        outlined
                                        prepend-icon="fas fa-pallet"
                                        label="Últ. Mov."
                                        v-model="filtros.ultima_posicao"
                                        clearable
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- Fim filtro -->
        <!-- Início principal -->
        <v-card>
            <v-toolbar
                flat
            >
                <v-icon>
                    fas fa-clock
                </v-icon>
                <v-toolbar-title
                    class="pl-4"
                >
                    Exportação Reclamação Correios
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-app-bar-nav-icon @click="download">
                    <v-icon>fas fa-file-download</v-icon>
                </v-app-bar-nav-icon>
                <v-app-bar-nav-icon @click="modal.filtro = true">
                    <v-icon>fas fa-search</v-icon>
                </v-app-bar-nav-icon>
                <v-app-bar-nav-icon @click="fechar">
                    <v-icon>fas fa-times</v-icon>
                </v-app-bar-nav-icon>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
                <v-row>
                    <v-col
                        class="px-6 py-6"
                    >
                        <v-data-table
                            dense
                            :headers="grid.objetos.cabecalho"
                            :items="grid.objetos.items"
                            :items-per-page="grid.objetos.porPagina"
                            hide-default-footer
                            class="elevation-1 pt-4"
                            :loading="grid.objetos.carregando"
                            item-key="codigo"
                            v-model="grid.objetos.itemSelecionado"
                        >
                            <!-- This template looks for headers with formatters and executes them -->
                            <template
                                v-for="header in grid.objetos.cabecalho.filter((header) =>
                                    header.hasOwnProperty('formatter')
                                )"
                                v-slot:[`item.${header.value}`]="{ header, value }"
                                >
                                {{ header.formatter(value) }}
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-row
                    class="mt-4"
                >
                    <v-col
                        cols="2"
                    >
                        <v-btn
                            fab
                            small
                            color="primary"
                            dark
                            @click="voltarTopo()"
                            style="margin-left: 10px;"
                        >
                            <v-icon>
                                fas fa-arrow-up
                            </v-icon>
                        </v-btn>
                    </v-col>
                    <v-col
                        cols="8"
                    >
                        <v-pagination
                            v-model="grid.objetos.paginaAtual"
                            :length="Math.ceil(grid.objetos.totalRegistros / this.grid.objetos.porPagina)"
                            @input="mudarPagina"
                            :total-visible="5"
                        ></v-pagination>
                    </v-col>
                    <v-col
                        cols="2"
                    >
                        <v-text-field
                            dense
                            outlined
                            label="Limite por página"
                            type="number"
                            autocomplete="off"
                            v-model="grid.objetos.porPagina"
                            clearable
                            style="padding-right: 10px;"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <!-- Fim principal -->
    </div>
</template>

<script>
import { baseApiUrl } from '@/global'
import axios from 'axios'
import moment from 'moment'

export default {
    name: 'Entregues',
    data: () => ({
        grid: {
            objetos: {
                cabecalho: [
                    { text: '#', value: 'codigo', sortable: false },
                    { text: 'Transp.', value: 'transportadora' },
                    { text: 'Rastreio', value: 'numero_rastreio' },
                    { text: 'Prazo', value: 'prazo_entrega' },
                    { text: 'Dt. Cons.', value: 'data_considerada', formatter: (x) => (x ? moment(x).format('DD/MM/yyyy') : null),  },
                    { text: 'Dt. Prev', value: 'data_prevista', formatter: (x) => (x ? moment(x).format('DD/MM/yyyy') : null),  },
                    { text: 'Dt. Ent.', value: 'data_entrega', formatter: (x) => (x ? moment(x).format('DD/MM/yyyy') : null),  },
                    { text: 'Cep Origem', value: 'remetente_cep' },
                    { text: 'Cep Destino', value: 'destinatario_cep' },
                    { text: 'Dt. Mov.', value: 'data_ultima_posicao', formatter: (x) => (x ? moment(x).format('DD/MM/yyyy HH:mm:ss') : null),  },
                    { text: 'Mov.', value: 'ultima_posicao' },
                ],
                items: [],
                carregando: false,
                paginaAtual: 1,
                totalRegistros: 0,
                porPagina: 10,
                itemSelecionado: [],
                coluna: 'objetos.codigo',
                direcao: 'asc',
                ordenacao: {
                    colunas: [
                        { text: 'Código', value: 'objetos.codigo' },
                        { text: 'Transportadora', value: 'transportadoras.transportadora' },
                        { text: 'Rastreio', value: 'objetos.numero_rastreio' },
                        { text: 'Prazo', value: 'objetos.prazo_entrega' },
                        { text: 'Dt. Cons.', value: 'objetos.data_considerada' },
                        { text: 'Dt. Prev', value: 'objetos.data_prevista' },
                        { text: 'Dt. Ent.', value: 'objetos.data_entrega' },
                        { text: 'Cep Origem', value: 'objetos.remetente_cep' },
                        { text: 'Cep Destino', value: 'objetos.destinatario_cep' },
                        { text: 'Dt. Mov.', value: 'objetos.data_ultima_posicao' },
                        { text: 'Mov.', value: 'objetos.ultima_posicao' },
                    ],
                    direcao: [
                        { text: 'ASC', value: 'asc' },
                        { text: 'DESC', value: 'desc' },
                    ]
                },
            },
        },
        modal: {
            filtro: false,
            dt_considerada: false,
            dt_prevista: false,
            dt_entrega: false,
            dt_ultima_posicao: false,
        },
        filtros: {
            codigo: '',
            cod_transportadora: '',
            numero_rastreio: '',
            prazo: '',
            dt_considerada: '',
            dt_prevista: '',
            dt_entrega: '',
            cep_origem: '',
            cep_destino: '',
            dt_ultima_posicao: '',
            ultima_posicao: '',
        },
        transportadoras: [],
    }),
    mounted () {
        this.carregarObjetos()
        this.carregarTransportadoras()
    },
    methods: {
        limparFiltros () {
            this.filtros.codigo = ''
            this.filtros.cod_transportadora = ''
            this.filtros.numero_rastreio = ''
            this.filtros.prazo = ''
            this.filtros.dt_considerada = ''
            this.filtros.dt_prevista = ''
            this.filtros.dt_entrega = ''
            this.filtros.cep_origem = ''
            this.filtros.cep_destino = ''
            this.filtros.dt_ultima_posicao = ''
            this.filtros.ultima_posicao = ''
        },
        fechar () {
            this.$router.push('/')
        },
        carregarObjetos () {
            this.grid.objetos.carregando = true
            let url = `${baseApiUrl}/objetos?page=${this.grid.objetos.paginaAtual}`
            url += `&coluna=${this.grid.objetos.coluna}&direcao=${this.grid.objetos.direcao}`
            if (this.filtros.codigo != '')
                url += `&codigo=${this.filtros.codigo}`
            if (this.filtros.cod_transportadora != '')
                url += `&cod_transportadora=${this.filtros.cod_transportadora}`
            if (this.filtros.numero_rastreio != '')
                url += `&numero_rastreio=${this.filtros.numero_rastreio}`
            if (this.filtros.prazo != '')
                url += `&prazo_entrega=${this.filtros.prazo}`
            if (this.filtros.dt_considerada != '')
                url += `&data_considerada=${this.filtros.dt_considerada}`
            if (this.filtros.dt_prevista != '')
                url += `&data_prevista=${this.filtros.dt_prevista}`
            if (this.filtros.dt_entrega != '')
                url += `&data_entrega=${this.filtros.dt_entrega}`
            if (this.filtros.cep_origem != '')
                url += `&remetente_cep=${this.filtros.cep_origem}`
            if (this.filtros.cep_destino != '')
                url += `&destinatario_cep=${this.filtros.cep_destino}`
            if (this.filtros.dt_ultima_posicao != '')
                url += `&data_ultima_posicao=${this.filtros.dt_ultima_posicao}`
            if (this.filtros.ultima_posicao != '')
                url += `&ultima_posicao=${this.filtros.ultima_posicao}`
            url += `&entregue=N`
            url += `&atrasado=S`
            if (Number(this.grid.objetos.porPagina) > 0)
                url += `&por_pagina=${this.grid.objetos.porPagina}`
            axios.get(url)
                .then(res => {
                    this.grid.objetos.items = res.data.dados.data
                    this.grid.objetos.totalRegistros = res.data.dados.total
                    this.grid.objetos.porPagina = Number(res.data.dados.per_page)
                })
                .finally(() => this.grid.objetos.carregando = false)
        },
        mudarPagina (pagina) {
            this.grid.objetos.paginaAtual = pagina
            this.carregarObjetos()
        },
        carregarTransportadoras () {
            let url = `${baseApiUrl}/transportadoras`
            axios.get(url)
                .then(res => {
                    this.transportadoras = res.data.transportadoras
                })
        },
        voltarTopo () {
            window.scrollTo(0, 0)
        },
        download () {
            let url = `${baseApiUrl}/reclamacao`
            url += `?coluna=${this.grid.objetos.coluna}&direcao=${this.grid.objetos.direcao}`
            if (this.filtros.codigo != '')
                url += `&codigo=${this.filtros.codigo}`
            if (this.filtros.cod_transportadora != '')
                url += `&cod_transportadora=${this.filtros.cod_transportadora}`
            if (this.filtros.numero_rastreio != '')
                url += `&numero_rastreio=${this.filtros.numero_rastreio}`
            if (this.filtros.prazo != '')
                url += `&prazo_entrega=${this.filtros.prazo}`
            if (this.filtros.dt_considerada != '')
                url += `&data_considerada=${this.filtros.dt_considerada}`
            if (this.filtros.dt_prevista != '')
                url += `&data_prevista=${this.filtros.dt_prevista}`
            if (this.filtros.dt_entrega != '')
                url += `&data_entrega=${this.filtros.dt_entrega}`
            if (this.filtros.cep_origem != '')
                url += `&remetente_cep=${this.filtros.cep_origem}`
            if (this.filtros.cep_destino != '')
                url += `&destinatario_cep=${this.filtros.cep_destino}`
            if (this.filtros.dt_ultima_posicao != '')
                url += `&data_ultima_posicao=${this.filtros.dt_ultima_posicao}`
            if (this.filtros.ultima_posicao != '')
                url += `&ultima_posicao=${this.filtros.ultima_posicao}`
            url += `&entregue=N`
            url += `&atrasado=S`
            axios.get(url)
                .then(res => {
                    if (res.data.retorno) {
                        window.open(res.data.url, "_blank")
                    }
                })
        }
    },
    watch: {
        'grid.objetos.porPagina': {
            handler: function (val, oldVal) {
                if (val != oldVal) {
                    if (val > 0) {
                        this.grid.objetos.porPagina = Number(val)
                        this.mudarPagina(1)
                    } else {
                        this.grid.objetos.porPagina = 10
                        this.mudarPagina(1)
                    }
                }
            },
        },
        'grid.objetos.coluna': {
            handler: function (val, oldVal) {
                if (val != oldVal) {
                    this.carregarObjetos()
                }
            },
        },
        'grid.objetos.direcao': {
            handler: function (val, oldVal) {
                this.carregarObjetos()
            },
        },
    },
}
</script>
